var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.debtor.id
    ? _c(
        "div",
        { staticClass: "UserProfile" },
        [
          _c("user-profile-navigation", {
            attrs: {
              id: _vm.id,
              "page-p-o-v": "debtor",
              routes: _vm.routes,
              user: _vm.debtor
            }
          }),
          _c(
            "transition",
            { attrs: { name: "Transition__opacity-fade" } },
            [
              !_vm.getDebtorInProgress
                ? _c("router-view", { staticClass: "UserProfile__router-view" })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }