<template>
  <div
    v-if="debtor.id"
    class="UserProfile"
  >
    <user-profile-navigation
      :id="id"
      :page-p-o-v="'debtor'"
      :routes="routes"
      :user="debtor"
    />

    <transition name="Transition__opacity-fade">
      <router-view
        v-if="!getDebtorInProgress"
        class="UserProfile__router-view"
      />
    </transition>
  </div>
</template>

<script>
// Helpers
import { Debtor } from '../../../utils/api'
// Components
import UserProfileNavigation from '../../../components/user-profile-navigation.vue'

export default {
  name: 'Debtor',

  components: {
    UserProfileNavigation,
  },

  props: {
    id: {
      type: String, // if reloaded, a String is used from the URL
      required: true
    },
    userIsMaster: { // prop from router
      type: Boolean,
      required: true,
    },
  },

  async created () {
    await this.getDebtor()
    if (this.userIsMaster) {
      this.routes.push({
        text: 'manage',
        to: { name: 'debtor-manage' },
      })
    }
  },

  beforeDestroy () {
    this.$store.commit('CLEAR_DEBTOR')
  },

  data () {
    return {
      getDebtorInProgress: false,
      routes: process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal'
        ? [
          {
            text: 'profile',
            to: { name: 'debtor-profile' },
          },
          {
            text: 'contacts',
            to: { name: 'debtor-contacts' },
          },
          {
            text: 'credit',
            to: { name: 'debtor-credit' },
          },
          {
            text: 'assignments',
            to: { name: 'debtor-assignments' },
          },
          {
            text: 'processing notes',
            to: { name: 'debtor-processing-notes' },
          },
        ]
        : [
          {
            text: 'profile',
            to: { name: 'debtor-profile' },
          },
          {
            text: 'contacts',
            to: { name: 'debtor-contacts' },
          },
          {
            text: 'credit',
            to: { name: 'debtor-credit' },
          },
          {
            text: 'assignments',
            to: { name: 'debtor-assignments' },
          },
          {
            text: 'data',
            to: { name: 'debtor-data' },
          },
          {
            text: 'processing notes',
            to: { name: 'debtor-processing-notes' },
          },
        ],
    }
  },

  computed: {
    debtor () {
      return this.$store.getters.debtor
    },
  },

  watch: {
    // Use Case: when searching and selecting a debtor from the base-select-search while you're
    // on debtor-profile, we must track for ID prop changes to get the new debtor info, because
    // simply passing a different ID to the router push function in base-select-search
    // won't trigger a page load since profile is the current route
    async id () {
      await this.getDebtor()
    },
  },

  methods: {
    async getDebtor () {
      this.$Progress.start()
      this.getDebtorInProgress = true

      try {
        this.$store.commit('STORE_DEBTOR', (await Debtor.get(this.id)).data)
        this.$Progress.finish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor "Get Debtor"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error.response)
        if (error.response.status === 404) {
          this.requestFailure({
            message: `We did not find a debtor by that ID (${this.$route.params.id})`
          })
          this.$router.push({ name: 'user-management' })
          return
        }
        this.requestFailure({ message: 'There was an issue retrieving the debtor' })
      }

      this.getDebtorInProgress = false
    },
  },
}
</script>

<style lang="sass">
@import '../../../styles/user-profile.sass'
</style>